import { HTTP_CP_ListSignInCompanies_Response_Company } from '@api-new/authservice';
import { HTTP_CP_LoadSignInBranding_Response } from '@api-new/companyservice';
import { createSelector } from '@ngrx/store';
import { ApiErrorCode } from '@shared/enums/api-error-code.enum';
import { AppState } from '@shared/models/app-state.model';
import { AuthState } from './auth.state';

export const selectAuthState = (state: AppState): AuthState => state.auth;

export const selectAuthLoading = createSelector(selectAuthState, (state: AuthState) => (state != null ? state.loading : false));

export const selectIsLoginFromParamsOrBrandingLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.auth.isLoginFromParamsLoading || state.loadingActions.isLoadingBranding,
);

export const selectRedirectUrl = createSelector(selectAuthState, (state: AuthState): string => state.auth.redirectUrl);

export const selectSignInEmail = createSelector(selectAuthState, (state: AuthState): string => state.auth.email);

export const selectLastTimeVerificationCodeSent = createSelector(
  selectAuthState,
  (state: AuthState): Date => state.auth.lastTimeVerificationCodeSent,
);

export const selectNoEmailFoundError = createSelector(selectAuthState, (state: AuthState): { noEmailFound: boolean } => {
  // Returning object on purpose to be emitted on each change
  return {
    noEmailFound: state.auth.doesEmailExist === false,
  };
});

export const signInErrorCode = createSelector(selectAuthState, (state: AuthState): string => state.auth.authErrorCode);

export const isInvalidSignInCode = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.auth.authErrorCode === ApiErrorCode.CLIENT_SIGN_IN__CODE_INVALID,
);

export const isExpiredSignInCode = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.auth.authErrorCode === ApiErrorCode.CLIENT_SIGN_IN__CODE_EXPIRED,
);

export const selectLastSignInCode = createSelector(selectAuthState, (state: AuthState): string => state.auth.lastSignInCode);

export const selectIsSendingVerificationCode = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.auth.isSendingVerificationCode,
);

export const selectAuthPagesBranding = createSelector(
  selectAuthState,
  (state: AuthState): HTTP_CP_LoadSignInBranding_Response => state.auth.branding,
);

export const selectSignInCompanies = createSelector(
  selectAuthState,
  (state: AuthState): HTTP_CP_ListSignInCompanies_Response_Company[] => state.auth.signInCompanies,
);
