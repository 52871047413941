import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'shared-link-container',
  imports: [IconComponent, RouterLink],
  templateUrl: './link-container.component.html',
  styleUrl: './link-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkContainerComponent {
  @Input() headline: string | undefined;
  @Input() routerLink: string | string[] | undefined;
  @Input() contentStyleClass: string | undefined;
  @Input() isActive = false;
  @Input() showArrowIcon = false;
}
