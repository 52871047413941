import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { HTTP_CP_ListSignInCompanies_Response_Company } from '@api-new/authservice';
import { paths } from '@platform/paths';
import { LinkContainerComponent } from '@shared-lib/components/link-container/link-container.component';
import { ValueType } from '@shared-lib/enums/value-type.enum';
import { ScreenSizeBreakpointNames, ScreenSizeBreakpointObserverService } from '@shared/services/screen-size-breakpoint-observer.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cp-login-company-select-item',
  standalone: true,
  imports: [CommonModule, LinkContainerComponent, NgOptimizedImage],
  templateUrl: './login-company-select-item.component.html',
  styleUrl: './login-company-select-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCompanySelectItemComponent {
  @Input({ required: true }) company: HTTP_CP_ListSignInCompanies_Response_Company;

  private readonly screenSizeBreakpointObserverService = inject(ScreenSizeBreakpointObserverService);

  protected readonly isMobile$ = this.screenSizeBreakpointObserverService.screenSizeBreakpointName$.pipe(
    map((name) => name === ScreenSizeBreakpointNames.MOBILE),
  );

  protected readonly paths = paths;
  protected readonly ValueType = ValueType;
}
