@if (!isLoading) {
  <div [ngClass]="{ inline: isInline, rows: !isInline }">
    <label class="form-field-label" [for]="name">
      {{ label }}
      @if (!hideRequiredIcon) {
        {{
          ngControl?.control != null &&
          (ngControl!.control! | abstractControlToFormControl).hasValidator(Validators.required)
            ? " *"
            : null
        }}
      }
      @if (labelTooltip) {
        <shared-icon icon="info" width="0.875rem" height="0.875rem" [pTooltip]="labelTooltip" />
      }
    </label>
    <div class="content" [ngClass]="{ needsEllipsis: needsEllipsis }" [pTooltip]="contentTooltip">
      <ng-content />
    </div>
    <div class="validation-messages">
      <div class="custom-validation-message" #customValidationMessage>
        <ng-content select="[validation]" />
      </div>

      @if (ngControl?.control != null && !customValidationMessage.hasChildNodes()) {
        <shared-validation-message [control]="ngControl!.control! | abstractControlToFormControl" />
      }
    </div>
  </div>
} @else {
  <p-skeleton height="1.5rem" styleClass="mb-2" />
}
