import { Injectable } from '@angular/core';
import { HTTP_AP_Affiliate } from '@api-new/companyservice/HTTP_AP_Affiliate';
import { HTTP_AP_Company } from '@api-new/companyservice/HTTP_AP_Company';
import { HTTP_AP_Company_ForDetails } from '@api-new/companyservice/HTTP_AP_Company_ForDetails';
import { HTTP_AP_Company_ForUserInterfaces } from '@api-new/companyservice/HTTP_AP_Company_ForUserInterfaces';
import { HTTP_AP_CreateAffiliate_Request } from '@api-new/companyservice/HTTP_AP_CreateAffiliate_Request';
import { HTTP_AP_CreateCompany_Request } from '@api-new/companyservice/HTTP_AP_CreateCompany_Request';
import { HTTP_AP_CreateNetwork_Request } from '@api-new/companyservice/HTTP_AP_CreateNetwork_Request';
import { HTTP_AP_DeleteCompany_Request } from '@api-new/companyservice/HTTP_AP_DeleteCompany_Request';
import { HTTP_AP_GetAffiliate_Request } from '@api-new/companyservice/HTTP_AP_GetAffiliate_Request';
import { HTTP_AP_GetCompanyAffiliates_Request } from '@api-new/companyservice/HTTP_AP_GetCompanyAffiliates_Request';
import { HTTP_AP_GetCompanyAffiliates_Response } from '@api-new/companyservice/HTTP_AP_GetCompanyAffiliates_Response';
import { HTTP_AP_GetCompany_ForDetails_Request } from '@api-new/companyservice/HTTP_AP_GetCompany_ForDetails_Request';
import { HTTP_AP_GetCompany_ForUserInterfaces_Request } from '@api-new/companyservice/HTTP_AP_GetCompany_ForUserInterfaces_Request';
import { HTTP_AP_GetCompany_Request } from '@api-new/companyservice/HTTP_AP_GetCompany_Request';
import { HTTP_AP_GetNetwork_Request } from '@api-new/companyservice/HTTP_AP_GetNetwork_Request';
import { HTTP_AP_ListAffiliates_ForSelect_Request } from '@api-new/companyservice/HTTP_AP_ListAffiliates_ForSelect_Request';
import { HTTP_AP_ListAffiliates_ForSelect_Response } from '@api-new/companyservice/HTTP_AP_ListAffiliates_ForSelect_Response';
import { HTTP_AP_ListAffiliates_ForTable_Request } from '@api-new/companyservice/HTTP_AP_ListAffiliates_ForTable_Request';
import { HTTP_AP_ListAffiliates_ForTable_Response } from '@api-new/companyservice/HTTP_AP_ListAffiliates_ForTable_Response';
import { HTTP_AP_ListCompanies_ForSelect_Request } from '@api-new/companyservice/HTTP_AP_ListCompanies_ForSelect_Request';
import { HTTP_AP_ListCompanies_ForSelect_Response } from '@api-new/companyservice/HTTP_AP_ListCompanies_ForSelect_Response';
import { HTTP_AP_ListCompanies_ForTable_Request } from '@api-new/companyservice/HTTP_AP_ListCompanies_ForTable_Request';
import { HTTP_AP_ListCompanies_ForTable_Response } from '@api-new/companyservice/HTTP_AP_ListCompanies_ForTable_Response';
import { HTTP_AP_ListNetworks_ForSelect_Response } from '@api-new/companyservice/HTTP_AP_ListNetworks_ForSelect_Response';
import { HTTP_AP_ListNetworks_ForTable_Response } from '@api-new/companyservice/HTTP_AP_ListNetworks_ForTable_Response';
import { HTTP_AP_Network } from '@api-new/companyservice/HTTP_AP_Network';
import { HTTP_AP_UpdateAffiliateLogo_Request } from '@api-new/companyservice/HTTP_AP_UpdateAffiliateLogo_Request';
import { HTTP_AP_UpdateAffiliate_Request } from '@api-new/companyservice/HTTP_AP_UpdateAffiliate_Request';
import { HTTP_AP_UpdateCompanyLogo_Request } from '@api-new/companyservice/HTTP_AP_UpdateCompanyLogo_Request';
import { HTTP_AP_UpdateCompanyNetwork_Request } from '@api-new/companyservice/HTTP_AP_UpdateCompanyNetwork_Request';
import { HTTP_AP_UpdateCompany_ForDetails_Request } from '@api-new/companyservice/HTTP_AP_UpdateCompany_ForDetails_Request';
import { HTTP_AP_UpdateCompany_ForUserInterfaces_Request } from '@api-new/companyservice/HTTP_AP_UpdateCompany_ForUserInterfaces_Request';
import { HTTP_AP_UpdateNetworkLogo_Request } from '@api-new/companyservice/HTTP_AP_UpdateNetworkLogo_Request';
import { HTTP_AP_UpdateNetwork_Request } from '@api-new/companyservice/HTTP_AP_UpdateNetwork_Request';
import { HTTP_CP_Company } from '@api-new/companyservice/HTTP_CP_Company';
import { HTTP_CP_GetCompany_Request } from '@api-new/companyservice/HTTP_CP_GetCompany_Request';
import { HTTP_CP_LoadSignInBranding_Request } from '@api-new/companyservice/HTTP_CP_LoadSignInBranding_Request';
import { HTTP_CP_LoadSignInBranding_Response } from '@api-new/companyservice/HTTP_CP_LoadSignInBranding_Response';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../../../../lib/services/application-http.service';

@Injectable({ providedIn: 'root' })
export class CompanyApiService {
  constructor(private readonly httpClient: ApplicationHttpClient) {}

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- GRPC Endpoints ----------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // --- HTTP Endpoints - Adviser Portal (ap) ------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_AP_CreateAffiliate(request: HTTP_AP_CreateAffiliate_Request): Observable<HTTP_AP_Affiliate> {
    return this.httpClient.post(`/company-service/ap/affiliates`, request);
  }

  HTTP_AP_CreateCompany(request: HTTP_AP_CreateCompany_Request): Observable<HTTP_AP_Company> {
    return this.httpClient.post(`/company-service/ap/companies`, request);
  }

  HTTP_AP_CreateNetwork(request: HTTP_AP_CreateNetwork_Request): Observable<HTTP_AP_Network> {
    return this.httpClient.post(`/company-service/ap/networks`, request);
  }

  HTTP_AP_DeleteCompany(request: HTTP_AP_DeleteCompany_Request): Observable<undefined> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.delete(`/company-service/ap/companies/${request.companyId}`, { params: updatedRequest });
  }

  HTTP_AP_GetAffiliate(request: HTTP_AP_GetAffiliate_Request): Observable<HTTP_AP_Affiliate> {
    const { affiliateId, ...updatedRequest } = request;
    return this.httpClient.get(`/company-service/ap/affiliates/${request.affiliateId}`, { params: updatedRequest });
  }

  HTTP_AP_GetCompany(request: HTTP_AP_GetCompany_Request): Observable<HTTP_AP_Company> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/company-service/ap/companies/${request.companyId}`, { params: updatedRequest });
  }

  HTTP_AP_GetCompany_ForDetails(request: HTTP_AP_GetCompany_ForDetails_Request): Observable<HTTP_AP_Company_ForDetails> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/company-service/ap/companies/${request.companyId}/ForDetails`, { params: updatedRequest });
  }

  HTTP_AP_GetCompany_ForUserInterfaces(
    request: HTTP_AP_GetCompany_ForUserInterfaces_Request,
  ): Observable<HTTP_AP_Company_ForUserInterfaces> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/company-service/ap/companies/${request.companyId}/ForUserInterfaces`, { params: updatedRequest });
  }

  HTTP_AP_GetCompanyAffiliates(request: HTTP_AP_GetCompanyAffiliates_Request): Observable<HTTP_AP_GetCompanyAffiliates_Response> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/company-service/ap/companies/${request.companyId}/affiliates`, { params: updatedRequest });
  }

  HTTP_AP_GetNetwork(request: HTTP_AP_GetNetwork_Request): Observable<HTTP_AP_Network> {
    const { networkId, ...updatedRequest } = request;
    return this.httpClient.get(`/company-service/ap/networks/${request.networkId}`, { params: updatedRequest });
  }

  HTTP_AP_ListAffiliates_ForSelect(
    request: HTTP_AP_ListAffiliates_ForSelect_Request,
  ): Observable<HTTP_AP_ListAffiliates_ForSelect_Response> {
    return this.httpClient.get(`/company-service/ap/affiliates/all/ForSelect`, { params: request });
  }

  HTTP_AP_ListAffiliates_ForTable(request: HTTP_AP_ListAffiliates_ForTable_Request): Observable<HTTP_AP_ListAffiliates_ForTable_Response> {
    return this.httpClient.get(`/company-service/ap/affiliates/all/ForTable`, { params: request });
  }

  HTTP_AP_ListCompanies_ForSelect(request: HTTP_AP_ListCompanies_ForSelect_Request): Observable<HTTP_AP_ListCompanies_ForSelect_Response> {
    return this.httpClient.get(`/company-service/ap/companies/all/ForSelect`, { params: request });
  }

  HTTP_AP_ListCompanies_ForTable(request: HTTP_AP_ListCompanies_ForTable_Request): Observable<HTTP_AP_ListCompanies_ForTable_Response> {
    return this.httpClient.get(`/company-service/ap/companies/all/ForTable`, { params: request });
  }

  HTTP_AP_ListNetworks_ForSelect(request?: undefined): Observable<HTTP_AP_ListNetworks_ForSelect_Response> {
    return this.httpClient.get(`/company-service/ap/networks/all/ForSelect`, { params: request });
  }

  HTTP_AP_ListNetworks_ForTable(request?: undefined): Observable<HTTP_AP_ListNetworks_ForTable_Response> {
    return this.httpClient.get(`/company-service/ap/networks/all/ForTable`, { params: request });
  }

  HTTP_AP_UpdateAffiliate(request: HTTP_AP_UpdateAffiliate_Request): Observable<HTTP_AP_Affiliate> {
    return this.httpClient.put(`/company-service/ap/affiliates/${request.affiliateId}`, request);
  }

  HTTP_AP_UpdateAffiliateLogo(request: HTTP_AP_UpdateAffiliateLogo_Request): Observable<HTTP_AP_Affiliate> {
    return this.httpClient.patch(`/company-service/ap/affiliates/${request.affiliateId}/logo`, request);
  }

  HTTP_AP_UpdateCompany_ForDetails(request: HTTP_AP_UpdateCompany_ForDetails_Request): Observable<HTTP_AP_Company_ForDetails> {
    return this.httpClient.put(`/company-service/ap/companies/${request.companyId}/ForDetails`, request);
  }

  HTTP_AP_UpdateCompany_ForUserInterfaces(
    request: HTTP_AP_UpdateCompany_ForUserInterfaces_Request,
  ): Observable<HTTP_AP_Company_ForUserInterfaces> {
    return this.httpClient.put(`/company-service/ap/companies/${request.companyId}/ForUserInterfaces`, request);
  }

  HTTP_AP_UpdateCompanyLogo(request: HTTP_AP_UpdateCompanyLogo_Request): Observable<HTTP_AP_Company> {
    return this.httpClient.patch(`/company-service/ap/companies/${request.companyId}/logo`, request);
  }

  HTTP_AP_UpdateCompanyNetwork(request: HTTP_AP_UpdateCompanyNetwork_Request): Observable<HTTP_AP_Company> {
    return this.httpClient.patch(`/company-service/ap/companies/${request.companyId}/network`, request);
  }

  HTTP_AP_UpdateNetwork(request: HTTP_AP_UpdateNetwork_Request): Observable<HTTP_AP_Network> {
    return this.httpClient.put(`/company-service/ap/networks/${request.networkId}`, request);
  }

  HTTP_AP_UpdateNetworkLogo(request: HTTP_AP_UpdateNetworkLogo_Request): Observable<HTTP_AP_Network> {
    return this.httpClient.put(`/company-service/ap/networks/${request.networkId}/logo`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Client Portal (cp) -------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_CP_GetCompany(request: HTTP_CP_GetCompany_Request): Observable<HTTP_CP_Company> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/company-service/cp/companies/${request.companyId}`, { params: updatedRequest });
  }

  HTTP_CP_LoadSignInBranding(request: HTTP_CP_LoadSignInBranding_Request): Observable<HTTP_CP_LoadSignInBranding_Response> {
    return this.httpClient.post(`/company-service/cp/LoadSignInBranding`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Dashly IQ (iq) ------------------------------------------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Universal Lead Capture (ulc) ---------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Extended (x) -------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- SPECIAL Endpoints -------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
}
