<div class="h-100 color-dark overflow-auto">
  <div class="flex-column gap-5 h-100">
    <button
      pButton
      type="button"
      class="p-button p-button-primary p-button-outlined small icon-only close-button"
      (click)="goToLoginPage()">
      <shared-icon icon="triangle-left" height="1rem" width="1rem" class="fill-dark" />
    </button>
    <div class="text-28 bold-800 text-center color-dark">Welcome to your new Mortgage Monitor</div>
    <form
      [formGroup]="acceptedTermsAndConditionsForm"
      (ngSubmit)="agreeAndLogin()"
      #ngForm="ngForm"
      class="flex-column gap-5 h-100">
      <span class="text-18 text-center line-height-130">
        As part of our ongoing commitment to providing you with expert advice, we monitor your
        mortgage 24/7 to ensure that you’re always on the deal that’s best suited to your
        circumstances.
      </span>
      <div class="flex-column gap-3">
        <div class="flex gap-3">
          <div class="third-party-getting-started-item-icon-container">
            <shared-icon class="fill-dark" icon="mortgage" height="2rem" width="2rem" />
          </div>
          <span class="text-16 line-height-150">
            <span class="bold">Track the equity</span>
            that's building up in your property and send you monthly reports
          </span>
        </div>
        <div class="flex gap-3">
          <shared-icon class="fill-dark" icon="notification" height="2rem" width="2rem" />
          <span class="text-16 line-height-150">
            <span class="bold">Notify you</span>
            the moment there is a better rate your rate changes or comes to an end
          </span>
        </div>
        <div class="flex gap-3">
          <shared-icon
            class="fill-dark"
            icon="file-with-magnifying-glass"
            height="2rem"
            width="2rem" />
          <span class="text-16 line-height-150">
            <span class="bold">Challenge your</span>
            current mortgage deal against thousands of other products
          </span>
        </div>
      </div>

      <div class="consent-container mt-auto">
        <div>
          <div class="flex gap-2 align-items-center">
            <cp-checkbox formControlName="agreeTerms" />
            <span class="terms-and-conditions-label">
              I agree to
              <a class="underline" target="_blank" [href]="tacLink">Terms of Use</a>
              and
              <a class="underline" target="_blank" [href]="privacyPolicyLink">Privacy Policy</a>
            </span>
          </div>
          <div class="text-14 color-red mt-1">
            @if (showWarnState()) {
              You have to agree with Terms of Use and Privacy Policy
            }
          </div>
        </div>
        <button
          pButton
          class="p-button p-button-primary w-100 centered"
          (click)="ngForm.onSubmit($event)"
          [disabled]="acceptedTermsAndConditionsForm.invalid"
          [loading]="loading$ | async">
          Continue
        </button>
      </div>
    </form>
  </div>
</div>
