import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@api-new/authservice';
import { CompanyApiService } from '@api-new/companyservice';
import { UserApiService } from '@api-new/userservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { paths } from '@platform/paths';
import { getClientUser } from '@shared/store/client-user/client-user.actions';
import { parseCustomErrorMessage } from '@shared/utils/parseCustomErrorMessage';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiErrorCode } from '../../enums/api-error-code.enum';
import { ErrorModel } from '../../models/app-state.model';
import { AuthService } from '../../services/auth.service';
import {
  agreeToTermsAndConditions,
  agreeToTermsAndConditionsFailure,
  agreeToTermsAndConditionsSuccess,
  askForTermsAndConditions,
  authError,
  checkLoginMail,
  checkLoginMailFailure,
  checkLoginMailSuccess,
  getAuthPagesBranding,
  getAuthPagesBrandingFailure,
  getAuthPagesBrandingSuccess,
  getListOfSignInCompanies,
  getListOfSignInCompaniesSuccess,
  login,
  loginFailure,
  loginMagicLink,
  loginMagicLinkFailure,
  loginMagicLinkSuccess,
  loginSuccess,
  loginWithToken,
  loginWithTokenSuccess,
  logoutAndReload,
  navigateToVerifyCode,
  navigateToVerifyCodeAndSendEmail,
  sendVerificationCode,
  sendVerificationCodeFailure,
  sendVerificationCodeSuccess,
} from './auth.action';
import { selectAuthPagesBranding, selectAuthState, selectRedirectUrl, selectSignInEmail } from './auth.selectors';

@Injectable()
export class AuthEffect {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly authApiService = inject(AuthApiService);
  private readonly userApiService = inject(UserApiService);
  private readonly companyApiService = inject(CompanyApiService);

  getListOfSignInCompanies = createEffect(() =>
    this.actions$.pipe(
      ofType(getListOfSignInCompanies),
      withLatestFrom(this.store.select(selectAuthState)),
      switchMap(([{ verificationCode }, { auth }]) =>
        this.authApiService
          .HTTP_CP_ListSignInCompanies({
            email: auth.email,
            code: verificationCode,
            ...(auth.branding?.companyId && { signInCompanyId: auth.branding.companyId }),
            ...(auth.branding?.networkId && { signInNetworkId: auth.branding.networkId }),
          })
          .pipe(
            map(({ companies }) => {
              return getListOfSignInCompaniesSuccess({
                companies: companies,
              });
            }),
            catchError((error: ErrorModel) => of(loginFailure({ error }))),
          ),
      ),
    ),
  );

  handleListOfSignInCompaniesSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(getListOfSignInCompaniesSuccess),
      map(({ companies }) => {
        if (companies.length > 1) {
          void this.router.navigate(['/', paths.AUTH, paths.auth.ACCOUNT_SELECT]);
          return { type: 'NO_ACTION' };
        } else {
          return login({ companyId: companies[0].id });
        }
      }),
    ),
  );

  login = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      withLatestFrom(this.store.select(selectAuthState)),
      switchMap(([{ companyId }, { auth }]) =>
        this.authApiService
          .HTTP_CP_SignIn_WithCode({
            email: auth.email,
            code: auth.verificationCode,
            ...(auth.branding?.companyId && { signInCompanyId: auth.branding.companyId }),
            ...(auth.branding?.networkId && { signInNetworkId: auth.branding.networkId }),
            profileCompanyId: companyId,
          })
          .pipe(
            map((signInResponse) => {
              return loginSuccess({
                signInResponse,
                link: auth.redirectUrl,
              });
            }),
            catchError((error: ErrorModel) => of(loginFailure({ error }))),
          ),
      ),
    ),
  );

  loginOverMagicLink = createEffect(() =>
    this.actions$.pipe(
      ofType(loginMagicLink),
      withLatestFrom(this.store.select(selectAuthPagesBranding)),
      map(([{ verificationCode }]) => {
        this.authService.logout();
        void this.router.navigate([paths.AUTH, paths.auth.LOGIN_VERIFICATION]);
        return getListOfSignInCompanies({ verificationCode });
      }),
    ),
  );

  loginSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess, loginMagicLinkSuccess),
      withLatestFrom(this.store.select(selectRedirectUrl)),
      map(([{ signInResponse }]) => {
        this.authService.setAccessToken(signInResponse.accessToken, false);
        return getClientUser();
      }),
    ),
  );

  loginWithTokenSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(loginWithTokenSuccess),
      withLatestFrom(this.store.select(selectRedirectUrl)),
      map(([{ signInResponse }]) => {
        this.authService.setAccessToken(signInResponse.accessToken, true);
        return getClientUser();
      }),
    ),
  );

  askForTermsAndConditions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(askForTermsAndConditions),
        tap(() => this.router.navigate([`${paths.AUTH}/${paths.auth.TERMS_AND_CONDITIONS}`])),
      ),
    { dispatch: false },
  );

  loginWithToken = createEffect(() =>
    this.actions$.pipe(
      ofType(loginWithToken),
      map(({ token }) => {
        this.authService.setAccessToken(token, true);

        return loginWithTokenSuccess({
          signInResponse: { accessToken: token },
        });
      }),
      catchError((error) => of(loginFailure({ error }))),
    ),
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutAndReload),
        switchMap(() => this.authApiService.HTTP_CP_SignOut()),
        tap(() => {
          this.authService.logout();
          this.router.navigate([``]).then(() => {
            requestAnimationFrame(() => window.location.reload());
          });
        }),
      ),
    { dispatch: false },
  );

  checkMailBeforeSignIn = createEffect(() =>
    this.actions$.pipe(
      ofType(checkLoginMail),
      switchMap(({ email }) =>
        this.userApiService.HTTP_CP_DoesEmailExist({ email }).pipe(
          map(({ doesEmailExist }) => checkLoginMailSuccess({ doesEmailExist })),
          catchError((error) => of(checkLoginMailFailure({ error }))),
        ),
      ),
    ),
  );

  checkLoginMailSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(checkLoginMailSuccess),
        tap(({ doesEmailExist }) => {
          if (doesEmailExist) {
            this.store.dispatch(navigateToVerifyCodeAndSendEmail());
          }
        }),
      ),
    { dispatch: false },
  );

  navigateToVerifyCodeAndSendEmail = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToVerifyCodeAndSendEmail),
        withLatestFrom(this.store.select(selectSignInEmail)),
        map(([_, email]) => {
          void this.router.navigate([paths.AUTH, paths.auth.LOGIN_VERIFICATION]).then((didNavigate) => {
            if (didNavigate && !!email) {
              this.store.dispatch(sendVerificationCode({ email }));
            }
          });
        }),
      ),
    { dispatch: false },
  );

  navigateToVerifyCode = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToVerifyCode),
        map(() => {
          void this.router.navigate([paths.AUTH, paths.auth.LOGIN_VERIFICATION]);
        }),
      ),
    { dispatch: false },
  );

  sendVerificationCode = createEffect(() =>
    this.actions$.pipe(
      ofType(sendVerificationCode),
      withLatestFrom(this.store.select(selectAuthPagesBranding)),
      switchMap(([{ email }, branding]) =>
        this.authApiService
          .HTTP_CP_ProvideClientUserSignInCode({
            email,
            ...(branding?.companyId && { signInCompanyId: branding.companyId }),
            ...(branding?.networkId && { signInNetworkId: branding.networkId }),
          })
          .pipe(
            map(() => sendVerificationCodeSuccess()),
            catchError((error) => of(sendVerificationCodeFailure({ error }))),
          ),
      ),
    ),
  );

  loginSuccessActionWithAgreement = createEffect(() =>
    this.actions$.pipe(
      ofType(agreeToTermsAndConditions),
      switchMap(() =>
        this.userApiService.HTTP_CP_AcceptTermsAndConditions().pipe(
          map(() => agreeToTermsAndConditionsSuccess()),
          catchError((error) => of(agreeToTermsAndConditionsFailure({ error }))),
        ),
      ),
    ),
  );

  agreeToTermsAndConditionsSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(agreeToTermsAndConditionsSuccess),
        withLatestFrom(this.store.select(selectRedirectUrl)),
        tap(([_, redirectUrl]) => (redirectUrl ? this.router.navigateByUrl(redirectUrl) : this.router.navigate([`/${paths.PLATFORM}`]))),
      ),
    { dispatch: false },
  );

  loginFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(loginFailure, loginMagicLinkFailure),
      map(({ error }: { type: string; error: ErrorModel }) => {
        return authError({ authErrorCode: parseCustomErrorMessage(error) as ApiErrorCode });
      }),
    ),
  );

  loginMagicLinkFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(loginMagicLinkFailure),
      map(({ error, code }) => {
        if (parseCustomErrorMessage(error) === ApiErrorCode.CLIENT_SIGN_IN__CODE_INVALID && code.length > 6) {
          return navigateToVerifyCodeAndSendEmail();
        }
        return navigateToVerifyCode();
      }),
    ),
  );

  loginExpiredFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(loginFailure),
      map(({ error }) => {
        return authError({ authErrorCode: parseCustomErrorMessage(error) as ApiErrorCode });
      }),
    ),
  );

  getAuthPagesBranding = createEffect(() =>
    this.actions$.pipe(
      ofType(getAuthPagesBranding),
      switchMap(({ domain }) =>
        this.companyApiService.HTTP_CP_LoadSignInBranding({ clientPortalDomain: domain }).pipe(
          map((branding) => getAuthPagesBrandingSuccess({ branding })),
          catchError((error) => of(getAuthPagesBrandingFailure({ error }))),
        ),
      ),
    ),
  );
}
