import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { HTTP_CP_ListSignInCompanies_Response_Company } from '@api-new/authservice';
import { HTTP_CP_LoadSignInBranding_Response } from '@api-new/companyservice';
import { Store } from '@ngrx/store';
import { paths } from '@platform/paths';
import { AppState } from '@shared/models/app-state.model';
import { AuthService } from '@shared/services/auth.service';
import {
  agreeToTermsAndConditions,
  checkLoginMail,
  clearLastSignInCode,
  getAuthPagesBranding,
  getListOfSignInCompanies,
  login,
  loginMagicLink,
  loginWithToken,
  resetStore,
  sendVerificationCode,
} from '@shared/store/auth/auth.action';
import {
  isExpiredSignInCode,
  isInvalidSignInCode,
  selectAuthLoading,
  selectAuthPagesBranding,
  selectIsLoginFromParamsOrBrandingLoading,
  selectIsSendingVerificationCode,
  selectLastSignInCode,
  selectLastTimeVerificationCodeSent,
  selectNoEmailFoundError,
  selectSignInCompanies,
  selectSignInEmail,
  signInErrorCode,
} from '@shared/store/auth/auth.selectors';
import { Observable } from 'rxjs';

@Injectable()
export class AuthFacadeService {
  private readonly store = inject(Store<AppState>);
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);

  readonly email$: Observable<string> = this.store.select(selectSignInEmail);
  readonly loading$: Observable<boolean> = this.store.select(selectAuthLoading);
  readonly isLoginFromParamsOrBrandingLoading$: Observable<boolean> = this.store.select(selectIsLoginFromParamsOrBrandingLoading);
  readonly authPagesBranding$: Observable<HTTP_CP_LoadSignInBranding_Response> = this.store.select(selectAuthPagesBranding);
  readonly isSendingVerificationCode$: Observable<boolean> = this.store.select(selectIsSendingVerificationCode);
  readonly authError$: Observable<string> = this.store.select(signInErrorCode);
  readonly lastTimeVerificationCodeSent$: Observable<Date> = this.store.select(selectLastTimeVerificationCodeSent);
  readonly noEmailFoundError$: Observable<{ noEmailFound: boolean }> = this.store.select(selectNoEmailFoundError);
  readonly isExpiredCodeError$: Observable<boolean> = this.store.select(isExpiredSignInCode);
  readonly isInvalidCodeError$: Observable<boolean> = this.store.select(isInvalidSignInCode);
  readonly verificationCodeFromFailedMagicLink$: Observable<string> = this.store.select(selectLastSignInCode);
  readonly signInCompanies$: Observable<HTTP_CP_ListSignInCompanies_Response_Company[]> = this.store.select(selectSignInCompanies);

  navigateToCheckMail(): void {
    void this.router.navigate([paths.AUTH, paths.auth.LOGIN]);
  }

  getAuthPagesBranding(domain: string): void {
    this.store.dispatch(
      getAuthPagesBranding({
        domain,
      }),
    );
  }

  sendVerificationCode(email: string): void {
    this.store.dispatch(sendVerificationCode({ email }));
  }

  getListOfSignInCompanies(verificationCode: string): void {
    this.store.dispatch(getListOfSignInCompanies({ verificationCode }));
  }

  loginMagicLink(email: string, verificationCode: string, redirectUrl?: string): void {
    this.store.dispatch(loginMagicLink({ email, verificationCode, redirectUrl }));
  }

  checkLoginMail(email: string): void {
    this.store.dispatch(checkLoginMail({ email }));
  }

  loginWithToken(token: string, link: string): void {
    this.store.dispatch(resetStore());
    this.store.dispatch(loginWithToken({ token, redirectUrl: link }));
  }

  agreeToTermsAndConditions(): void {
    this.store.dispatch(agreeToTermsAndConditions());
  }

  clearLastSentVerificationCode(): void {
    this.store.dispatch(clearLastSignInCode());
  }

  selectCompany(companyId: string): void {
    this.store.dispatch(login({ companyId }));
  }

  goToLoginPage(): void {
    this.authService.logout();
    void this.router.navigate(['/', paths.AUTH, paths.auth.LOGIN]);
  }
}
