<div class="h-100 color-dark">
  <div class="flex-column gap-5 h-100">
    <button
      pButton
      type="button"
      class="p-button p-button-primary p-button-outlined small icon-only close-button"
      [routerLink]="['/', paths.AUTH, paths.auth.LOGIN]">
      <shared-icon icon="triangle-left" height="1rem" width="1rem" class="fill-dark" />
    </button>

    <div class="text-28 bold-800 text-center color-dark">
      Welcome to your <br />
      Mortgage Monitor
    </div>
    <div class="text-16 text-center">
      Click the link we’ve sent you to
      <b>{{ email }}</b>
    </div>
    <form
      [formGroup]="verificationCodeForm"
      class="form-wrapper flex-column justify-content-between border-radius-3 bg-light p-4 gap-3">
      @if ((isSendingVerificationCode$ | async) === false) {
        <span class="text-14">
          Opening the email from a different device?
          <br />
          Enter <b>the verification code</b> from the email.
        </span>
      } @else {
        <div class="text-12">
          Sending verification code to <span class="bold">{{ email }}</span
          >...
        </div>
      }

      <div class="flex-column">
        <p-input-otp
          inputmode="numeric"
          class="w-100 justify-content-evenly"
          [formControl]="verificationCodeForm.controls.verificationCode"
          [integerOnly]="true"
          [length]="6">
          <ng-template #input let-token let-events="events" let-index="index">
            <input
              type="text"
              class="p-inputotp-input"
              [maxLength]="1"
              (input)="events.input($event)"
              pKeyFilter="int"
              inputmode="numeric"
              (keydown)="events.keydown($event)"
              [attr.value]="token" />
          </ng-template>
        </p-input-otp>
        <div class="validation-message mt-2">
          @if (isInvalidCodeError$ | async) {
            <div>You have entered an invalid code or link.</div>
          }
          @if (isExpiredCodeError$ | async) {
            <div>You have entered an expired code or link.</div>
          }
          @if ((isExpiredCodeError$ | async) && (isSendingVerificationCode$ | async)) {
            <div>We are sending you a new one to your e-mail.</div>
          }
        </div>
      </div>
      <button
        pButton
        class="p-button-primary mt-2"
        (click)="getListOfSignInCompanies()"
        [disabled]="verificationCodeForm.invalid || !!(authError$ | async)"
        [loading]="loading$ | async">
        Verify
      </button>
    </form>
    @if (authError$ | async) {
      <div>
        @if ((authError$ | async) === ApiErrorCode.CLIENT_SIGN_IN__CODE_EXPIRED) {
          <div class="text-14">The code you provided is expired!</div>
          <div class="text-12">
            Please click the resend to get a new verification code sent to
            <b>{{ email }}.</b>
          </div>
        }
        @if ((authError$ | async) === ApiErrorCode.CLIENT_SIGN_IN__CODE_INVALID) {
          <div class="text-14">The code you provided is not valid!</div>
          <div class="text-12">
            Please click the resend to get a new verification code sent to
            <b>{{ email }}.</b>
          </div>
        }
        @if (
          (authError$ | async) !== ApiErrorCode.CLIENT_SIGN_IN__CODE_EXPIRED &&
          (authError$ | async) !== ApiErrorCode.CLIENT_SIGN_IN__CODE_INVALID
        ) {
          <div class="text-14">Unexpected error occurred!</div>
          <div class="text-12">
            We are really sorry about this! Please try again later, or contact customer support.
          </div>
        }
      </div>
    }
    <div class="text-14 bold-800 mt-auto text-center color-primary">
      @if (timeLeftTillResendEnabled) {
        You can resend the code in {{ timeLeftTillResendEnabled }} seconds.
      } @else {
        Didn’t get anything from us?
        <span class="underline" (click)="resendCode()">Resend the email.</span>
      }
    </div>
  </div>
</div>
